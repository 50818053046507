import React, { useState } from "react";
import AlertMassage from './controls/SnackBar';

export default function useAlert() {
    const [status, setStatusBase] = useState();
    const [open, setOpen] = React.useState(true);

    const handleAlertClose = (event, reason) =>{
        setOpen(false);
        setStatusBase("");
    }

    const Alert = props => (
        <AlertMassage
            style={{
                position: 'relative'
            }}
            severityType={status.severityType}
            open={open}
            key={status.key}
            message={status.msg}
            handleClose={handleAlertClose}
        />
    )

    const showSuccess = () => {
        setOpen(true);
        setStatusBase({ msg: "Success", key: Math.random(), severityType: "success" });
    }

    const showError = (message) => {
        const errorMessage = message ? message : "Something went wrong please try again soon";
        setStatusBase({ msg: errorMessage, key: Math.random(), severityType: "error" });
        setOpen(true);
    }

    return {
        Alert,
        status,
        showSuccess,
        showError,
        handleAlertClose
    }
}
