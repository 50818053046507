import React, { useEffect, useState } from 'react';
import Dialog from "../../components/controls/Dialog";
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Input from '../../components/controls/Input';
import * as featuresService from '../../services/featuresService';
import { useForm, Form } from '../../components/useForm';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Typography } from '@mui/material';
import useAlert from '../../components/useAlert';
import useLinearProgress from '../../components/useLinearProgress';

const initialFValues = {}

const ClientFeatureDialog = (props) => {
    const { open, selectedRow } = props;
    const [features, setFeatures] = useState([]);
    const [changedFeatures, setChangedFeatures] = useState([]);

    useEffect(() => {
        if (open) {
            setIsLoading(true);
            handleAlertClose();
            featuresService.getFeatures(selectedRow.id)
                .then((response) => {
                    const result = response.data.items;
                    if (result)
                        setFeatures(result);
                })
                .catch(error => {
                    showError(error.response.data.message);
                }).finally(() => {
                    setIsLoading(false);
                });
        }
        //eslint-disable-next-line
    }, [open])


    const {
        errors,
        setErrors,
        handleClose
    } = useForm(initialFValues, props);


    const {
        Alert,
        status,
        showSuccess,
        showError,
        handleAlertClose
    } = useAlert();

    const {
        LinearPorgress,
        isLoading,
        setIsLoading
    } = useLinearProgress(true);

    const submit = () => {
        if (validate()) {
            let keys = changedFeatures.flatMap(it => Object.keys(it));
            let values = changedFeatures.flatMap(it => Object.values(it));
            let result = Object.fromEntries(keys.map((_, i) => [keys[i], values[i]]));
            featuresService.updateFeatures(selectedRow.id, result)
                .then(() => {
                    showSuccess();
                    resetDialog();
                    handleClose();
                }).catch(error => {
                    showError(error.response.data.message);
                });
        }
    };

    const validate = () => {
        let temp = {}
        setErrors({
            ...temp
        });
        return Object.values(temp).every(x => x === '');
    }

    const handleInputChange = (e) => {
        let feature = { [e.target.name]: e.target.value };
        handleChange(e, feature);
    }

    const handleCntrolLobaelChange = (e) => {
        let feature = { [e.target.name]: e.target.checked };
        handleChange(e, feature);
    }

    const handleChange = (e, feature) => {
        let keys = changedFeatures.map(it => Object.keys(it));
        let featureIndex = keys.findIndex(it => it === e.target.name);
        if (featureIndex !== -1) {
            changedFeatures[featureIndex] = feature;
            setChangedFeatures([...changedFeatures]);
        }
        else {
            setChangedFeatures([...changedFeatures, feature]);
        }
    }

    const resetDialog = () => {
        setChangedFeatures([]);
        setFeatures([]);
    }

    return (
        <Form>
            {status && status.severityType === "error" ? <Alert /> : null}
            <Dialog
                title="Client Features"
                open={open}
                handleClose={() => {
                    handleClose();
                    resetDialog();
                }}

            >
                {isLoading ? <LinearPorgress />
                    : !isLoading && features.length > 0 ?
                        <React.Fragment>
                            <DialogContent>
                                {features.map(it => {
                                    if (it.type === 'BOOL')
                                        return (<FormGroup key={it.featureId} >
                                            <FormControlLabel name={it.featureId} control={
                                                <Switch defaultChecked={it.value ? it.value : false} onChange={handleCntrolLobaelChange} />
                                            } label={it.featureId} />
                                            <Typography
                                                variant="body2"
                                                color='#B2B2B2'
                                            >{it.description}</Typography>
                                        </FormGroup>)
                                    else if (it.type === 'STRING')
                                        return (
                                            <React.Fragment key={it.featureId}>
                                                <Input
                                                    {...it.featureId === "CHAT_SUMMARY_MAIL_ADDRESS" && { type: "email" }}
                                                    label={it.featureId}
                                                    name={it.featureId}
                                                    defValue={it.value}
                                                    onChange={handleInputChange}
                                                    error={errors.name}
                                                />
                                                <Typography
                                                    variant="body2"
                                                    color='#B2B2B2'
                                                >{it.description}</Typography>
                                            </React.Fragment>
                                        )

                                    else
                                        return (
                                            <React.Fragment key={it.featureId}>
                                                <Input
                                                    type="number"
                                                    label={it.featureId}
                                                    name={it.featureId}
                                                    defValue={it.value}
                                                    onChange={handleInputChange}
                                                    error={errors.name}
                                                />
                                                <Typography
                                                    variant="body2"
                                                    color='#B2B2B2'
                                                >{it.description}</Typography>
                                            </React.Fragment>
                                        )

                                })}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        resetDialog();
                                        handleClose();
                                    }}>Cancel</Button>
                                <Button variant="contained" onClick={submit}>Save</Button>
                            </DialogActions>
                        </React.Fragment>
                        : <Typography variant='h6' sx={{ textAlign: 'center', m: 4 }}>No Features Available...</Typography>
                }
            </Dialog>
        </Form >
    )
};

export default ClientFeatureDialog;
