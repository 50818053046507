import * as Api from '../api/Api';
import createAxios from '../api/WiseOwl';

export const getAll = () => {
    return createAxios().get(Api.PATH_RASAS);
};

export const removeRasaConfig = (id) => {
    return createAxios().delete(Api.PATH_RASAS + "/" + id);
}

export const createRasaConfig = (values) => {
    return createAxios().post(Api.PATH_RASAS, values)
};

export const update = (id, values) => {
    return createAxios().put(`${Api.PATH_RASAS}/${id}`,values);
};