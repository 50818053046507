import * as Api from '../api/Api';
import createAxios from '../api/WiseOwl';

export const getFeatures = (clientId) => {
    return createAxios().get(`${Api.PATH_CLIENTS}/${clientId}${Api.SUB_PATH_CLIENT_FEATURES}`);
}


export const updateFeatures = (clientId, values) => {
    return createAxios().put(`${Api.PATH_CLIENTS}/${clientId}${Api.SUB_PATH_CLIENT_FEATURES}`, values);
}
