import axios from "axios";
import keycloak from "./Keycloak";

const createAxios = () => axios.create({
    baseURL: "/wo",
    headers: {
        Authorization: "Bearer " + keycloak.token,
        ContentType: "application/json"
    }
});

export default createAxios;
