import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";

export default function useTable(records, headCells) {

    const TblContainer = props => (
        <Table >
            {props.children}
        </Table>
    )

    const TblHead = props => {
        const { align } = props;
        return (
            <TableHead>
                <TableRow

                >
                    {headCells.map(column =>
                        <TableCell
                            key={column.label}
                            {...(align && { align: align })}
                            sx={{
                                color: '#999999'
                            }}
                        >
                            {column.label}

                        </TableCell>
                    )}
                    <TableCell
                        {...(align && { align: align })}
                        sx={{
                            color: '#999999'
                        }}
                    >
                        Actions
                    </TableCell>
                </TableRow>
            </TableHead>
        )
    }

    return {
        TblContainer,
        TblHead
    }
}