import React, { useEffect, useState } from 'react';
import Dialog from "../../components/controls/Dialog";
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { useForm, Form } from '../../components/useForm';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import * as clientService from '../../services/clientService'
import { Box, IconButton, TextField, Typography } from '@mui/material';
import useAlert from '../../components/useAlert';
import useLinearProgress from '../../components/useLinearProgress';

const initialValues = {
    defaultGlobalAudiotransforms: []
}
const TTSSettingDialog = (props) => {
    const { open, selectedRow } = props;
    const [audiotransforms, setAudiotransforms] = useState([]);
    const [clientAudiotransforms, setClientAudiotransforms] = useState([]);

    useEffect(() => {
        if (open) {
            setIsLoading(true);
            handleAlertClose();
            clientService.getTextToSpeechTransformInherited(selectedRow.id).then((response) => {
                const result = response.data;
                if (result) {
                    if (result.items) {
                        initialValues.defaultGlobalAudiotransforms = result.items;
                        setAudiotransforms(result.items);
                    }
                }
            })
                .catch(error => {
                    showError(error.response.data.message);
                })

            clientService.getTextToSpeechTransform(selectedRow.id).then((response) => {
                const result = response.data;
                if (result.items)
                    setClientAudiotransforms(result.items);
            })
                .catch(error => {
                    showError(error.response.data.message);
                }).finally(() => {
                    setIsLoading(false);
                })
        }
        //eslint-disable-next-line
    }, [open])

    const {
        errors,
        handleClose
    } = useForm(initialValues, props);

    const {
        Alert,
        status,
        showSuccess,
        showError,
        handleAlertClose
    } = useAlert();

    const {
        LinearPorgress,
        isLoading,
        setIsLoading
    } = useLinearProgress(true);


    const submit = () => {
        if (validate()) {
            clientService.updateTextToSpeechTransform(selectedRow.id, { items: clientAudiotransforms })
                .then(() => {
                    showSuccess();
                    resetDialog();
                    handleClose();
                })
                .catch(error => {
                    showError(error.response.data.message);
                });
        }
    };

    const validate = () => {
        let temp = {}

        // setErrors({
        //     ...temp
        // });
        return Object.values(temp).every(x => x === '');
    }

    const handleOptionalInputChange = (index, event) => {
        const clientAudiotransform = clientAudiotransforms[index];
        const { name, value } = event.target
        const tmp = {
            ...clientAudiotransform,
            [name]: value
        };
        let newClientAudiotransforms = [...clientAudiotransforms];
        newClientAudiotransforms[index] = tmp;
        setClientAudiotransforms(newClientAudiotransforms);
    }

    const resetDialog = () => {
        setAudiotransforms([]);
        setClientAudiotransforms([]);
    }

    return (
        <Form>
            {status ? <Alert /> : null}
            <Dialog
                fullWidth={true}
                title="Client - TTS Settings"
                open={open}
                handleClose={() => {
                    handleClose();
                    resetDialog();
                }}>
                {isLoading ? <LinearPorgress /> :
                    (!isLoading && (clientAudiotransforms.length > 0 || audiotransforms.length > 0)) ?
                        <React.Fragment>
                            <Box m={1}>
                                {clientAudiotransforms.map((it, index) =>
                                    <React.Fragment key={index}>
                                        <TextField
                                            label="From"
                                            name="transformFrom"
                                            defaultValue={it.transformFrom}
                                            onChange={(e) => {
                                                handleOptionalInputChange(index, e)
                                            }}
                                            error={errors.transformFrom}
                                            sx={{
                                                m: 1
                                            }}
                                        />
                                        <TextField
                                            label="To"
                                            name="transformTo"
                                            defaultValue={it.transformTo}
                                            onChange={(e) => {
                                                handleOptionalInputChange(index, e)
                                            }}
                                            error={errors.transformTo}
                                            sx={{
                                                m: 1
                                            }}
                                        />
                                        <IconButton onClick={() => {
                                            setClientAudiotransforms(clientAudiotransforms.filter((item) => item.transformFrom !== it.transformFrom));
                                        }}>
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </React.Fragment>
                                )}
                            </Box>
                            <DialogActions>
                                <IconButton onClick={() => {
                                    setClientAudiotransforms([...clientAudiotransforms, {}]);
                                }}>
                                    <AddIcon />
                                </IconButton>
                            </DialogActions>
                            <Box m={1}>
                                <Typography sx={{
                                    m: 1
                                }}
                                    variant="h6"
                                    gutterBottom component="div">
                                    Inherited from Global
                                </Typography>
                                {audiotransforms.map((it, index) =>
                                    <React.Fragment key={index}>
                                        <TextField
                                            color='primary'
                                            {...(clientAudiotransforms.find(item => item.transformFrom === it.transformFrom) && { style: { backgroundColor: '#E5E5E5' } })}
                                            disabled
                                            label="From"
                                            name="transformFrom"
                                            value={it.transformFrom}
                                            sx={{
                                                m: 1
                                            }}
                                        />
                                        <TextField
                                            {...(clientAudiotransforms.find(item => item.transformFrom === it.transformFrom) && { style: { backgroundColor: '#E5E5E5' } })}
                                            disabled
                                            label="To"
                                            name="transformTo"
                                            value={it.transformTo}
                                            sx={{
                                                m: 1
                                            }}
                                        />
                                        <IconButton onClick={() => {
                                            let selectedItem = audiotransforms.find(audio => audio.transformFrom === it.transformFrom);
                                            if (!clientAudiotransforms.find(it => it.transformFrom === selectedItem.transformFrom))
                                                setClientAudiotransforms([...clientAudiotransforms, {
                                                    transformFrom: selectedItem.transformFrom,
                                                    transformTo: selectedItem.transformTo,
                                                }]);
                                        }
                                        }>
                                            <EditIcon />
                                        </IconButton>
                                    </React.Fragment>
                                )}
                            </Box>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        handleClose();
                                        resetDialog();
                                    }}>Cancel</Button>
                                <Button variant="contained" onClick={submit}>Confirm</Button>
                            </DialogActions>
                        </React.Fragment>
                        : <Typography variant='h6' sx={{ textAlign: 'center', m: 4 }}>No TTS Available...</Typography>
                }
            </Dialog>
        </Form >
    );
}

export default TTSSettingDialog;