import * as Api from '../api/Api';
import createAxios from '../api/WiseOwl';

export const getAll = () => {
    return createAxios().get(Api.PATH_UNNECESSARY_PHRASES);
};

export const update = (values) => {
    return createAxios().put(Api.PATH_UNNECESSARY_PHRASES, values);
};
