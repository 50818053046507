import { Box } from '@mui/material';
import Clients from '../../Pages/Clients/Clients';

const MaterialTable = (props) => {

    return (
        <Box style={{
            flex: 1,
            margin: '90px 20px 20px 10px'
        }}>
            <Clients />
        </Box>
    );
}

export default MaterialTable;
