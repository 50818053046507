import React from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { Toolbar as MUIToolbar } from '@mui/material';
import { Grid } from '@mui/material';

const Toolbar = (props) => {
    const { title, setOpen, setDialogType } = props
    return (
        <MUIToolbar>
            <Grid container >
                <Grid item xs={4}>
                    <Button style={{
                        color: '#58A813',
                    }} endIcon={<AddIcon />} onClick={() => {
                        setOpen();
                        if (setDialogType)
                            setDialogType();
                    }
                    }>
                        {title}
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    {props.children}
                </Grid>
                <Grid item xs={4}>
                    {/* <InputBase
                        placeholder="Search"
                        startAdornment={<SearchIcon fontSize='small' />}
                    /> */}
                </Grid>
            </Grid>
        </MUIToolbar >
    )
};

export default Toolbar;