import React, { useEffect, useState } from 'react';
import Dialog from '../../components/controls/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import * as clientService from '../../services/clientService';
import * as watsonService from '../../services/watsonService';
import * as rasaService from '../../services/rasaService';
import * as PREDEFINED_VALUES from '../../const/consts'
import useLinearProgress from '../../components/useLinearProgress';
import { FormControl, Select, Typography } from '@mui/material'
import useAlert from '../../components/useAlert';
import { useForm} from '../../components/useForm';

const initValue = {
    defaultAssistantId: null,
    items: []
}

const ClientAssistantDialog = (props) => {
    const [defaultAssistant, setDefaultAssistant] = useState();
    const [supportiveAssistants, setSupportiveAssistants] = useState([]);
    const { open, selectedRow } = props;
    const assistantService = selectedRow.engineType ===  PREDEFINED_VALUES.engineTypes.WATSON? watsonService: rasaService;

    useEffect(() => {
        if (open === true) {
            setIsLoading(true);
            handleAlertClose();
            clientService.getClientAssistantConfig(selectedRow.id, selectedRow.engineType)
                .then((response) => {
                    const result = response.data;
                    if (result.defaultAssistantId)
                        initValue.defaultAssistantId = result.defaultAssistantId;
                    if (result.items)
                        initValue.items = result.items;
                })
                .catch(error => {
                    showError(error.response.data.message);
                }).finally(() => {
                getAllAssistants();
            })

        }
        //eslint-disable-next-line
    }, [open])

    const {
        handleClose,
    } = useForm(initValue, props);

    const {
        Alert,
        status,
        showSuccess,
        showError,
        handleAlertClose
    } = useAlert();

    const {
        LinearPorgress,
        isLoading,
        setIsLoading
    } = useLinearProgress(true);

    const getAllAssistants = () => {
        assistantService.getAll()
            .then((response) => {
                const result = response.data.items;
                if (result) {
                    setSupportiveAssistants(result);
                    setDefaultAssistant(result.find(it => it.id === initValue.defaultAssistantId));
                }
            })
            .catch(error => {
                showError(error.response.data.message);
            }).finally(() => {
            setIsLoading(false)
        });
    }

    const submit = () => {
        if (defaultAssistant)
            initValue.defaultAssistantId = defaultAssistant.id;
        initValue.items = initValue.items.map(it => parseInt(it));
        clientService.updateAssistant(selectedRow.id, initValue, selectedRow.engineType)
            .then(() => {
                showSuccess();
                resetDialog();
                handleClose();
            })
            .catch(error => {
                showError(error.response.data.message);
            });
    };

    const handleSelectChange = (event) => {
        setDefaultAssistant(supportiveAssistants.find(it => it.id === event.target.value));
        initValue.items = initValue.items.filter(it => it !== event.target.value);
    }

    const handleCheckboxChange = (event, id) => {
        if (event.target.checked === true)
            initValue.items.push(id)
        else
            initValue.items = initValue.items.filter(it => it !== id)
    }

    const resetDialog = () => {
        setDefaultAssistant();
        setSupportiveAssistants([]);
        initValue.defaultAssistantId = null;
        initValue.items = [];
    }

    return (
        <React.Fragment>
            {status ? <Alert /> : null}
            <Dialog
                title={`Client-${selectedRow.engineType} Configuration`}
                open={open}
                handleClose={() => {
                    resetDialog();
                    handleClose();
                }}>
                {isLoading ? <LinearPorgress /> :
                    (!isLoading && supportiveAssistants.length > 0) ?
                        < React.Fragment >
                            <DialogContent>
                                <InputLabel>Default</InputLabel>
                                <FormControl
                                    style={{ margin: 10 }}
                                    size="small"
                                    variant="outlined"
                                >
                                    <Select
                                        key={defaultAssistant ? defaultAssistant.id : null}
                                        defaultValue={defaultAssistant ? defaultAssistant.id : null}
                                        onChange={handleSelectChange}>
                                        {supportiveAssistants.map(it =>
                                            <MenuItem key={it.id} value={it.id}>{it.name}</MenuItem>
                                        )}

                                    </Select>
                                </FormControl>
                                <InputLabel >Supportive</InputLabel>
                                <FormGroup>
                                    {defaultAssistant ? supportiveAssistants.filter(it => it.id !== defaultAssistant.id).map((it, index) => (
                                        <FormControlLabel
                                            key={it.id}
                                            onChange={(event) => handleCheckboxChange(event, it.id)}
                                            control={<Checkbox {...(initValue.items.find(def => def === it.id) && { defaultChecked: true })} />}
                                            label={it.name}
                                        />
                                    )) : supportiveAssistants.map((it, index) => (
                                        <FormControlLabel
                                            key={it.id}
                                            onChange={(event) => handleCheckboxChange(event, it.id)}
                                            control={<Checkbox {...(initValue.items.find(def => def === it.id) && { defaultChecked: true })} />}
                                            label={it.name}
                                        />
                                    ))
                                    }
                                </FormGroup>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        resetDialog();
                                        handleClose();
                                    }}>Cancel</Button>
                                <Button variant="contained" onClick={submit}>Confirm</Button>
                            </DialogActions>
                        </React.Fragment>
                        : <Typography variant='h6' sx={{ textAlign: 'center', m: 4 }}>No Watsons Avaliable...</Typography>
                }
            </Dialog >
        </React.Fragment >
    );
}

export default ClientAssistantDialog;