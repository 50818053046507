import React from 'react';
import Input from '../../components/controls/Input';
import Select from '../../components/controls/Select';
import {FormHelperText} from '@mui/material';
import * as PREDEFINED_VALUES from '../../const/consts';
import locale from 'react-json-editor-ajrm/locale/en';
import {TextareaAutosize} from "@material-ui/core";

const FEConfigOptional = (props) => {
    const {options, setOptions, index, errors = {}} = props;

    const handleOptionalInputChange = (key, e) => {
        const option = options[key];
        const {name, value} = e.target
        const tmp = {
            ...option,
            [name]: value
        };
        let newOptions = [...options];
        newOptions[index] = tmp;
        setOptions(newOptions);
    }

    const handleOptionalJSONInputChange = (key, input) => {
        const option = options[key];
        option.configJson = input;
        setOptions(options);
    }

    return (
        <React.Fragment>
            <Select
                label="Platform"
                name="platform"
                defValue={options[index].platform}
                options={PREDEFINED_VALUES.mobile_platforms}
                onChange={(e) => {
                    handleOptionalInputChange(index, e)
                }}
            />
            {errors.items ? <FormHelperText sx={{marginLeft: 1.5}} error>{errors.items}</FormHelperText> : null}
            <Input
                label="SDK version"
                name="sdkVersion"
                value={options[index].sdkVersion}
                onChange={(e) => {
                    handleOptionalInputChange(index, e)
                }}
                error={errors.items}
            />
            <Input
                label="Platform version"
                name="platformVersion"
                value={options[index].platformVersion}
                onChange={(e) => {
                    handleOptionalInputChange(index, e)
                }}
                error={errors.items}
            />
            <p></p>
            <TextareaAutosize
                placeholder={"Optional JSON"}
                defaultValue={options[index].configJson}
                locale={locale}
                style={{minHeight: 200, width: 500}}
                onChange={event => {
                    handleOptionalJSONInputChange(index, event.target.value);
                }}
            />
            {errors[`items[${index}].configJson`]
                ? <FormHelperText error>{errors[`items[${index}].configJson`]}</FormHelperText> : null
            }

        </React.Fragment>
    );

}

export default FEConfigOptional;
