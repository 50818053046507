import keycloak from '../api/Keycloak';

const ADMIN = "ADMIN"
const ADMIN_WATSON = [ADMIN, "ADMIN_WATSON"]
const ADMIN_RASA = [ADMIN, "ADMIN_RASA"]
const ADMIN_TTS = [ADMIN, "ADMIN_TTS"]
const ADMIN_UNNECESSARY_PHRASES = [ADMIN, "ADMIN_UNNECESSARY_PHRASES"]


export const isWatsonAdmin = () => ADMIN_WATSON.some(requieredRole => keycloak.realmAccess.roles.includes(requieredRole));

export const isRasaAdmin = () => ADMIN_RASA.some(requieredRole => keycloak.realmAccess.roles.includes(requieredRole));

export const isTTSAdmin = () => ADMIN_TTS.some(requieredRole => keycloak.realmAccess.roles.includes(requieredRole));

export const isUnnPhrasesAdmin = () => ADMIN_UNNECESSARY_PHRASES.some(requieredRole => keycloak.realmAccess.roles.includes(requieredRole));

export const isAdmin = () => keycloak.realmAccess.roles.includes(ADMIN);

export const displayAdminSettingsMenu = () => keycloak.realmAccess.roles.some(it=> it.startsWith(ADMIN));
