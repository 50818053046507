import React from 'react';
import Dialog from "./controls/Dialog";
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useForm } from './useForm';
import { Typography } from '@mui/material';

const AlertDialog = (props) => {
    const { open, confirmAction } = props;

    const {
        handleClose,
    } = useForm(null, props);


    return (
        <React.Fragment>
            <Dialog
                title="Confirmation Dialog"
                open={open}
                handleClose={handleClose}>
                <DialogContent sx={{ textAlign: 'center' }}>
                    <Typography>
                        Are you sure?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        onClick={() => {
                            handleClose();
                            confirmAction();
                        }}
                    >Confirm</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default AlertDialog;