import React, {useEffect, useState} from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import WatsonConfigForm from "./WatsonConfigForm";
import ToolBar from "../../../components/Layout/ToolBar";
import useTable from '../../../components/useTable';
import * as watsonService from '../../../services/watsonService';
import ActionsTableCell from '../../../components/MaterialTable/ActionsTableCell';
import useAlert from '../../../components/useAlert';
import AlertDialog from '../../../components/AlertDialog';
import * as AssistantConfigs from '../../AssistantConfigs/AssistantConfigs';

const watsonConfigColumns = [
    ...AssistantConfigs.assistantConfigColumns,
    { id: 'assistant-id', label: 'Assistant ID' },
    {id: 'version', label: 'VERSION'}
];

const WatsonConfigs = (props) => {
    const [watsonConfigs, setWatsonConfigs] = useState([]);
    const [selectedRow, setSelectedRow] = useState("");
    const [open, setOpen] = useState(false);
    const [dialogType, setDialogType] = useState("");

    const {
        TblContainer,
        TblHead
    } = useTable(WatsonConfigs, watsonConfigColumns);

    useEffect(() => {
        getWatsonConfigs();
        //eslint-disable-next-line
    }, []);

    const {
        Alert,
        status,
        showSuccess,
        showError
    } = useAlert();

    const getWatsonConfigs = () => {
        watsonService.getAll().then((response) => {
            const result = response.data.items;
            if (result) {
                setWatsonConfigs(response.data.items);
            }
        })
            .catch(error => {
                showError(error.response.data.message);
            });
    }

    const handleDeleteClicked = () => {
        watsonService.removeWatsonConfig(selectedRow.id)
            .then(() => {
                showSuccess();
                getWatsonConfigs();
            })
            .catch(error => {
                showError(error.response.data.message);
            });
    }

    return (
        <React.Fragment>
            {status ? <Alert/> : null}
            <ToolBar
                title="Create new Watson config"
                setOpen={() => {
                    setDialogType(AssistantConfigs.ASSISTANT_DIALOGUES.CREATE_EDIT);
                    setOpen(true)
                }}
            />
            <TblContainer>
                <TblHead/>
                <TableBody>
                    {watsonConfigs && watsonConfigs.map(key => (
                        <TableRow
                            key={key.id}
                            onClick={() => setSelectedRow(key)}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell>{key.name}</TableCell>
                            <TableCell>{key.assistantId}</TableCell>
                            <TableCell>{key.version}</TableCell>
                            <ActionsTableCell
                                onEdit={() => {
                                    setDialogType(AssistantConfigs.ASSISTANT_DIALOGUES.CREATE_EDIT);
                                    setOpen(true);
                                }}
                                onDelete={() => {
                                    setDialogType(AssistantConfigs.ASSISTANT_DIALOGUES.CONFIRMATION);
                                    setOpen(true);
                                }}
                            />
                        </TableRow>
                    ))}
                </TableBody>
            </TblContainer>
            {dialogType === AssistantConfigs.ASSISTANT_DIALOGUES.CREATE_EDIT ?
                <WatsonConfigForm
                    open={open}
                    setOpen={setOpen}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    getWatsonConfigs={getWatsonConfigs}
                /> : dialogType === AssistantConfigs.ASSISTANT_DIALOGUES.CONFIRMATION ?
                    <AlertDialog
                        open={open}
                        setOpen={setOpen}
                        setSelectedRow={setSelectedRow}
                        confirmAction={handleDeleteClicked}
                    />
                    : null
            }
        </React.Fragment>
    );
}

export default WatsonConfigs;
