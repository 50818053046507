import { TextField } from '@mui/material';
import React from 'react';

const Input = (props) => {
    const { type, name, label, value, defValue, size, error = null, onChange, fullWidth, disabled, inputProps, InputLabelProps } = props;
    return (
        <TextField
            sx={{
                marginTop: 1,
                '& .MuiInputBase-input': {
                    font: 'Kanit',
                    fontSize: 18,
                    fontWeight: 400,
                    color: '#333333'
                },
                '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    maxWidth: 520,
                    height: 40
                },
            }}
            color='primary'
            label={label}
            value={value}
            name={name}
            onChange={onChange}
            fullWidth={!fullWidth}
            size={size || "small"}
            {...value && { value: value }}
            {...defValue && { defaultValue: defValue }}
            {...(type && { type: type })}
            {...(error && { error: true, helperText: error })}
            {...(disabled && { disabled: disabled })}
            {...(inputProps && { inputProps: inputProps })}
            {...(InputLabelProps && { InputLabelProps: InputLabelProps })}
        />
    )
};

export default Input;