import React, { useEffect, useState } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import RasaConfigForm from "./RasaConfigForm";
import ToolBar from "../../../components/Layout/ToolBar";
import useTable from '../../../components/useTable';
import * as rasaService from '../../../services/rasaService';
import ActionsTableCell from '../../../components/MaterialTable/ActionsTableCell';
import useAlert from '../../../components/useAlert';
import AlertDialog from '../../../components/AlertDialog';
import * as AssistantConfigs from '../../AssistantConfigs/AssistantConfigs';

const RasaConfigs = () => {
    const [rasaConfigs, setRasaConfigs] = useState([]);
    const [selectedRow, setSelectedRow] = useState("");
    const [open, setOpen] = useState(false);
    const [dialogType, setDialogType] = useState("");

    const {
        TblContainer,
        TblHead
    } = useTable(RasaConfigs, AssistantConfigs.assistantConfigColumns);

    useEffect(() => {
        getRasaConfigs();
        //eslint-disable-next-line
    }, []);

    const {
        Alert,
        status,
        showSuccess,
        showError
    } = useAlert();

    const getRasaConfigs = () => {
        rasaService.getAll().then((response) => {
            const result = response.data.items;
            if (result) {
                setRasaConfigs(response.data.items);
            }
        })
            .catch(error => {
                showError(error.response.data.message);
            });
    }

    const handleDeleteClicked = () => {
        rasaService.removeRasaConfig(selectedRow.id)
            .then(() => {
                showSuccess();
                getRasaConfigs();
            })
            .catch(error => {
                showError(error.response.data.message);
            });
    }

    return (
        <React.Fragment>
            {status ? <Alert /> : null}
            <ToolBar
                title="Create new Rasa config"
                setOpen={() => {
                    setDialogType(AssistantConfigs.ASSISTANT_DIALOGUES.CREATE_EDIT);
                    setOpen(true)
                }}
            />
            <TblContainer>
                <TblHead />
                <TableBody>
                    {rasaConfigs && rasaConfigs.map((key, value) => (
                        <TableRow
                            key={key.id}
                            onClick={() => setSelectedRow(key)}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell>{key.name}</TableCell>
                            <ActionsTableCell
                                onEdit={() => {
                                    setDialogType(AssistantConfigs.ASSISTANT_DIALOGUES.CREATE_EDIT);
                                    setOpen(true);
                                }}
                                onDelete={() => {
                                    setDialogType(AssistantConfigs.ASSISTANT_DIALOGUES.CONFIRMATION);
                                    setOpen(true);
                                }}
                            />
                        </TableRow>
                    ))}
                </TableBody>
            </TblContainer>
            {dialogType === AssistantConfigs.ASSISTANT_DIALOGUES.CREATE_EDIT ?
                <RasaConfigForm
                    open={open}
                    setOpen={setOpen}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    getRasaConfigs={getRasaConfigs}
                /> : dialogType === AssistantConfigs.ASSISTANT_DIALOGUES.CONFIRMATION ?
                    <AlertDialog
                        open={open}
                        setOpen={setOpen}
                        setSelectedRow={setSelectedRow}
                        confirmAction={handleDeleteClicked}
                    />
                    : null
            }
        </React.Fragment>
    );
}

export default RasaConfigs;
