import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import ProjectsMenu from '../Menu/ProjectsMenu';
import { useLocation, useNavigate } from "react-router-dom";
import ProjectContext from '../../context/project-context';
import keycloak from "../../api/Keycloak";
import LogoutMenu from '../Menu/LogoutMenu';
import * as authService from '../../services/authService';
import { IconButton} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SettingsIcon from '../controls/SettingsIcon';

const ProjectSelect = (props) => {
    const [logoutMenuOpen, setLogoutMenuOpen] = useState(false);
    const [logoutOpenAnchorE1, setLogoutAnchorEl] = useState(null);
    const [projectsAnchorEl, setProjectsAnchorEl] = useState(null);
    const [projectsMenuOpen, setProjectsMenuOpen] = useState(false);
    const [userProfile, setUserProfile] = useState("");
    const [displayBackButton, setDisplayBackButton] = useState(false);
    const { projects, selectedProject, setSelectedProject } = useContext(ProjectContext);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/tabs")
            setDisplayBackButton(true)
        else
            setDisplayBackButton(false)
    }, [location])

    useEffect(() => {
        keycloak.loadUserProfile()
            .then((response) => {
                setUserProfile(response);
                console.log("Response", response);
            })
            .catch(error => {
                console(error.response);
            });
    }, [])


    const handleSttingMenuClick = (event) => {
        navigate(`/tabs`);
    };

    const handleProjectsMenuClick = (event) => {
        setProjectsMenuOpen(true);
        setProjectsAnchorEl(event.currentTarget);
    };

    const handleLogoutClicked = (event) => {
        setLogoutMenuOpen(true);
        setLogoutAnchorEl(event.target);
    };

    return (
        <ProjectContext.Provider value={{ selectedProject }}>
            <React.Fragment>
                {displayBackButton ?
                    <IconButton
                        sx={{
                            maxHeight: 45,
                            border: '1px solid rgb(153, 153, 153)',
                            backgroundColor: 'white',
                            color: '#999999',
                            borderRadius: 2,
                            fontSize: 15
                        }}
                        onClick={() => {
                            navigate(`/`);
                        }}>
                        <ArrowBackIosNewIcon />
                        {`Back to ${selectedProject ? selectedProject.name : null}`}
                    </IconButton>
                    :
                    <IconButton
                        sx={{
                            maxHeight: 45,
                            border: '1px solid rgb(153, 153, 153)',
                            backgroundColor: 'white',
                            color: '#999999',
                            borderRadius: 2,
                            fontSize: 15
                        }}
                        onClick={handleProjectsMenuClick}>
                        {selectedProject ? selectedProject.name : ""}
                        <ArrowDropDownIcon
                        />

                    </IconButton>
                }
                <Button
                    onClick={handleSttingMenuClick}
                    {...!authService.displayAdminSettingsMenu() && { sx: { display: 'none' } }}
                >
                    <SettingsIcon />
                </Button>
                <ProjectsMenu
                    open={projectsMenuOpen}
                    anchorEl={projectsAnchorEl}
                    setOpen={setProjectsMenuOpen}
                    setAnchorEl={setProjectsAnchorEl}
                    setSelectedProject={setSelectedProject}
                    projects={projects}
                />
            </React.Fragment>
            <LogoutMenu
                open={logoutMenuOpen}
                setOpen={setLogoutMenuOpen}
                anchorEl={logoutOpenAnchorE1}
                setAnchorEl={setLogoutAnchorEl}
            />
            <IconButton
                onClick={handleLogoutClicked}
                style={{
                    borderRadius: 30,
                    width: 53,
                    height: 53,
                    color: '#58A813',
                    border: '1px solid rgb(88, 168, 19)',
                    fontSize: 25
                }}
            >
                {(userProfile
                    && userProfile.firstName
                    && userProfile.lastName) ?
                    (userProfile.firstName.substring(0, 1).concat(userProfile.lastName.substring(0, 1)))
                    : "?"}
            </IconButton>
        </ProjectContext.Provider >
    );
}

export default ProjectSelect;
