export const mobile_platforms = ["IOS", "ANDROID"];

export const languages = [
    "CZ",
    "EN",
    "DE",
    "SK",
    "EN_ZA"
];
export const platforms = [
    "WEB_PUBLIC",
    "WEB_SECURED",
    "MOBILE_APP",
    "IM",
    "TESTING_TOOL",
    "PHONE",
    "PENETRATION_TEST",
    "MONITORING_TOOL",
    "GOOGLE_ASSISTANT",
    "SMART_WATCH",
    "AI_3D_AVATAR"

];
export const clientTypes = [
    "BANK",
    "ORGANIZER",
    "SHOPPING",
];

export const ttsTypes = [
    "GOOGLE",
    "MICROSOFT"
];

export const engineTypes = {
    WATSON: "WATSON",
    RASA: "RASA"
}

export const DEFAULT_BASE_URL = "http://generic-cart:8080"