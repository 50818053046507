import * as Api from '../api/Api';
import createAxios from '../api/WiseOwl';
import * as PREDEFINED_VALUES from '../const/consts';

export const getClients = () => {
    return createAxios().get(Api.PATH_CLIENTS);
}

export const createClient = (values) => {
    return createAxios().post(Api.PATH_CLIENTS, values);
};

export const updateClient = (id, values) => {
    return createAxios().put(Api.PATH_CLIENTS + "/" + id, values);
}

export const removeClient = (id) => {
    return createAxios().delete(Api.PATH_CLIENTS + "/" + id);
}

export const getTextToSpeechTransform = (clientId) => {
    return createAxios().get(`${Api.PATH_CLIENTS}/${clientId}${Api.SUB_PATH_CLIENT_TEXT_TO_SPEECH_TRANSFORM}`);
}

export const getTextToSpeechTransformInherited = (clientId) => {
    return createAxios().get(`${Api.PATH_CLIENTS}/${clientId}${Api.SUB_PATH_CLIENT_TEXT_TO_SPEECH_TRANSFORM}?type=INHERITED`);
};


export const updateTextToSpeechTransform = (clientId, values) => {
    return createAxios().put(`${Api.PATH_CLIENTS}/${clientId}${Api.SUB_PATH_CLIENT_TEXT_TO_SPEECH_TRANSFORM}`, values);
}

export const getClientAssistantConfig = (id, engineType) => {
    if (engineType === PREDEFINED_VALUES.engineTypes.WATSON)
        return getClientWatsonConfig(id)
    else
        return getClientRasaConfig(id)
};

export const updateAssistant = (id, values, engineType) => {
    if (engineType === PREDEFINED_VALUES.engineTypes.WATSON)
        return updateWatson(id, values)
    else
        return updateRasa(id, values)
};

const getClientWatsonConfig = (id) => {
    return createAxios().get(`${Api.PATH_CLIENTS}/${id}${Api.SUB_PATH_CLIENT_WATSON}`);
};

const updateWatson = (id, values) => {
    return createAxios().put(`${Api.PATH_CLIENTS}/${id}${Api.SUB_PATH_CLIENT_WATSON}`, values);
};

const getClientRasaConfig = (id) => {
    return createAxios().get(`${Api.PATH_CLIENTS}/${id}${Api.SUB_PATH_CLIENT_RASA}`);
};

const updateRasa = (id, values) => {
    return createAxios().put(`${Api.PATH_CLIENTS}/${id}${Api.SUB_PATH_CLIENT_RASA}`, values);
};