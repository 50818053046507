import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Menu } from '@mui/material';


const ProjectsMenu = (props) => {
    const { anchorEl, setAnchorEl, open, setOpen, projects, setSelectedProject } = props;
    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const handleChange = (project) => {
        setSelectedProject(project)
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    transform: 'translateX(0%) translateY(0%)',
                }
            }}
        >
            {projects ?
                projects.map((project) => (
                    <MenuItem
                        key={project.id}
                        value={project.id}
                        onClick={() => {
                            handleChange(project);
                            handleClose();
                        }}
                    >{project.name}</MenuItem>)
                ) : null
            }
        </Menu>
    )
}

export default ProjectsMenu;