import React from "react";
import ProjectSelect from "../ProjectSelect/ProjectSelect";
import { AppBar, Grid, Toolbar } from "@mui/material";

const Header = () => {
    return (
        <AppBar position="fixed">
            <Toolbar
                style={{
                    backgroundColor: 'white',
                    color: 'black',
                }}
            >
                <Grid container >
                    <Grid item
                        xs={2}>
                        <img
                            alt="Main Logo"
                            src="/Logo-transparent.png"
                            style={{
                                width: 75
                            }}
                        />
                        <span>Config Tool</span>
                    </Grid>
                    <Grid container
                        justifyContent='flex-end'
                        alignItems='center'
                        item xs={10}>
                        <React.Fragment>
                            <ProjectSelect />
                        </React.Fragment>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar >
    )
};

export default Header;