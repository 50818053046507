import React, { useEffect, useState } from 'react';
import * as projectService from '../services/projectService';

const ProjectContext = React.createContext({
    projects: [],
    selectedProject: null,
    setSelectedProject: () => { }
})

export const ProjectProvider = (props) => {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState();

    useEffect(() => {
            projectService.getProjects().then((response) => {
                const result = response.data.items;
                setProjects(response.data.items);
                if (result.length > 0) {
                    setSelectedProject(result[0]);
                }
            })
                .catch(error => {
                    console.error("Error while posting data", error.response.data)
                });
    }, []);


    return (
        <ProjectContext.Provider value={{ projects, selectedProject, setSelectedProject }}>
            {props.children}
        </ProjectContext.Provider>
    )
}

export default ProjectContext;