import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import * as audioTransformService from '../../services/audioTransformService';
import { useForm } from '../../components/useForm';
import Select from '../../components/controls/Select';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Grid, IconButton, TextField } from '@mui/material';
import useLinearProgress from '../../components/useLinearProgress';
import useAlert from '../../components/useAlert';
import Toolbar from '../../components/Layout/ToolBar';
import * as PREDEFINED_VALUES from '../../const/consts';


const initValues = {
    transformFrom: "",
    transformTo: ""
}

const TTSSettingDialog = (props) => {
    const [audiotransforms, setAudiotransforms] = useState([]);

    const {
        errors,
        resetForm,
        handleError,
    } = useForm(initValues, props);

    const {
        LinearPorgress,
        isLoading,
        setIsLoading
    } = useLinearProgress(false);

    const {
        Alert,
        status,
        showSuccess,
        showError
    } = useAlert();

    useEffect(() => {
        getAudioTransforms();
        //eslint-disable-next-line
    }, [])

    const getAudioTransforms = () => {
        setIsLoading(true);
        audioTransformService.getAll().then((response) => {
            const result = response.data;
            if (result.items) {
                setAudiotransforms(result.items);
            }
        })
            .catch(error => {
                showError(error.response.data.message);
            }).finally(() => {
                setIsLoading(false);
            });
    }

    const submit = () => {
        audioTransformService.update({ items: audiotransforms })
            .then((response) => {
                showSuccess();
                resetForm();
            })
            .catch(error => {
                handleError(error.response.data.fields);
                showError(error.response.data.message);
            });
    };

    const onAdd = () => {
        let tmp = [initValues, ...audiotransforms];
        setAudiotransforms(tmp);
    }

    const onDelete = (key) => {
        console.log(key);
        let items = [...audiotransforms.filter((it, index) => index !== key)];
        setAudiotransforms(items);
        resetForm();
    }

    const handleOptionalInputChange = (index, e) => {
        console.log(index);
        const option = audiotransforms[index];
        const { name, value } = e.target;
        const tmp = {
            ...option,
            [name]: value
        };
        audiotransforms[index] = tmp;
        setAudiotransforms(audiotransforms);
    }

    return (
        <React.Fragment>
            {status ? <Alert /> : null}
            {isLoading ? <LinearPorgress /> :
                <React.Fragment>
                    <Toolbar
                        title="Add new Audiotransform"
                        setOpen={() => onAdd()}
                    >
                    </Toolbar>
                    <Grid
                        container
                        justifyContent='space-between'
                        alignItems='flex-start'
                        spacing={1}
                    >
                        {audiotransforms.map((it, indx) => {
                            let index = audiotransforms.length - indx - 1;
                            return (
                                <React.Fragment key={index}>
                                    <Grid item xs={2}>
                                        <TextField
                                            label="From"
                                            name="transformFrom"
                                            defaultValue={it.transformFrom}
                                            onChange={(e) => {
                                                console.log(index);
                                                handleOptionalInputChange(indx, e);

                                            }}
                                            {...(errors[`items[${indx}].transformFrom`] && { error: true, helperText: errors[`items[${indx}].transformFrom`] })}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label="To"
                                            name="transformTo"
                                            defaultValue={it.transformTo}
                                            onChange={(e) => handleOptionalInputChange(indx, e)}
                                            {...(errors[`items[${indx}].transformTo`] && { error: true, helperText: errors[`items[${indx}].transformTo`] })}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Select
                                            label="Language"
                                            name="language"
                                            defValue={it.language}
                                            options={PREDEFINED_VALUES.languages}
                                            onChange={(e) => handleOptionalInputChange(indx, e)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Select
                                            label="TTS Provider"
                                            name="ttsProvider"
                                            defValue={it.ttsProvider}
                                            options={PREDEFINED_VALUES.ttsTypes}
                                            onChange={(e) => handleOptionalInputChange(indx, e)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Select
                                            label="Client type"
                                            name="clientType"
                                            defValue={it.clientType}
                                            options={PREDEFINED_VALUES.clientTypes}
                                            onChange={(e) => handleOptionalInputChange(indx, e)}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => onDelete(indx)}>
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={1}>
                                    </Grid>
                                </React.Fragment>
                            )
                        })}
                    </Grid>
                    <Button variant="contained" sx={{ marginTop: 2 }} onClick={submit}>Confirm changes</Button>
                </React.Fragment>
            }
        </React.Fragment>
    );
}

export default TTSSettingDialog;