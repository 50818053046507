import { FormControl, InputLabel, MenuItem, Select as MuiSelect} from '@mui/material';
import React from 'react';

const Select = (props) => {
    const { key, name, label, defValue, size, onChange, options } = props;

    return (
        <FormControl
            size={size || "small"}
            variant="outlined"
            fullWidth
            sx={{
                marginTop: 2,
                '& .MuiInputBase-input': {
                    font: 'Kanit',
                    fontSize: 18,
                    fontWeight: 400,
                    color: '#333333',
                    borderRadius: 10,
                },
                '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    maxWidth: 520,
                    height: 40
                },
            }}
            {...key && { key: key }}
        >
            <InputLabel>{label}</InputLabel>
            <MuiSelect
                color='primary'
                label={label}
                name={name}
                defaultValue={defValue ?? 'None'}
                onChange={onChange}
            >
                {!defValue &&
                    <MenuItem value="None">
                        None
                    </MenuItem>
                }
                {options.map(option =>
                    <MenuItem
                        key={option} value={option}>{option}</MenuItem>
                )}
            </MuiSelect>
        </FormControl>
    )
}

export default Select;