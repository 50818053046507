import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Projects from '../Pages/Projects/Projects';
import WatsonConfigs from '../Pages/AssistantConfigs/Watson/WatsonConfigs';
import RasaConfigs from '../Pages/AssistantConfigs/Rasa/RasaConfigs';
import AudioTransform from '../Pages/AudioTransforms/AudioTransform';
import UnnecessaryPhrases from '../Pages/UnnecessaryPhrases/UnnecessaryPhrases';
import * as authService from '../services/authService';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0)
    const tabs = []


    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const createTabs = () => {
        if (authService.isAdmin()) {
            tabs.push(
                {
                    title: 'Projects',
                    component: <Projects />
                }
            )
        }
        if (authService.isWatsonAdmin()) {
            tabs.push(
                {
                    title: 'Watson',
                    component: <WatsonConfigs />
                }
            )
        }
        if (authService.isRasaAdmin()) {
            tabs.push(
                {
                    title: 'Rasa',
                    component: <RasaConfigs />
                }
            )
        }
        if (authService.isTTSAdmin()) {
            tabs.push(
                {
                    title: 'Audio Transforms',
                    component: <AudioTransform />
                }
            )
        }
        if (authService.isUnnPhrasesAdmin()) {
            tabs.push(
                {
                    title: 'Unnecessary phrases',
                    component: <UnnecessaryPhrases />
                }
            )
        }
    }
    return (
        <Box sx={{ flex: 1, margin: '90px 20px 20px 10px', borderRadius: 1, backgroundColor: 'white' }}>
            <Box sx={{ borderBottom: 1, marginTop: 2, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
                    {createTabs()}
                    {tabs.map((it, index) => (
                        <Tab
                            key={index}
                            label={it.title}
                            {...a11yProps(index)}
                        />
                    ))}
                </Tabs>
            </Box>

            {tabs.map((it, index) => (
                <TabPanel
                    key={index}
                    value={value}
                    index={index}
                >
                    {it.component}
                </TabPanel>
            ))}
        </Box>
    );
}
