import React from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, TableCell } from '@mui/material';

const ActionsTableCell = (props) => {
    const { onEdit, onDelete, align, children } = props;

    return (
        <TableCell {...(align && { align: align })}>
            {children}
            <IconButton
               id="deletebutton"
                color='primary'
                onClick={onEdit}
            >
                <EditIcon
                />
            </IconButton>
            <IconButton
            
                color='primary'
                onClick={onDelete}
            >
                <DeleteOutlineIcon />
            </IconButton>
        </TableCell>
    );
}

export default ActionsTableCell;
