import React, { useEffect, useState } from 'react';
import * as unPhrasesService from '../../services/unPhrasesService';
import { Autocomplete, Box, Button, Chip, TextField } from '@mui/material';
import useAlert from '../../components/useAlert';

const UnnecessaryPhrases = () => {
    const [unnecessaryPhrases, setUnnecessaryPhrases] = useState([]);

    useEffect(() => {
        unPhrasesService.getAll().then((response) => {
            const result = response.data.items;
            if (result) {
                setUnnecessaryPhrases(result);
            }
        })
            .catch(error => {
                console.error("Error while posting data", error.response.data)
            });

    }, []);

    const submit = () => {
        unPhrasesService.update({ items: unnecessaryPhrases })
            .then((response) => {
                showSuccess();
            }).catch(error => {
                showError(error.response.data.message);
            });
    }

    const {
        Alert,
        status,
        showSuccess,
        showError
    } = useAlert();

    return (
        <Box>
            {status ? <Alert /> : null}
            <Autocomplete
                key={unnecessaryPhrases.length}
                multiple
                options={[]}
                defaultValue={unnecessaryPhrases}
                freeSolo={true}
                onChange={(e, value) => {
                    setUnnecessaryPhrases(value);
                }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            label={option} {...getTagProps({ index })} />
                    ))
                }
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label="Unnecessary phrases"
                    />
                }
            />
            <Box mt={2}>
                <Button  variant="contained" onClick={submit}>Confirm changes</Button>
            </Box>
        </Box>
    )
}

export default UnnecessaryPhrases;
