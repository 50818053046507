import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Menu } from '@mui/material';
import keycloak from '../../api/Keycloak';


const LogoutMenu = (props) => {
    const { anchorEl, setAnchorEl, open, setOpen } = props;
    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    transform: 'translateX(0%) translateY(0%)',
                }
            }}
        >
            <MenuItem
                onClick={() => {
                    keycloak.logout();
                }}>
                Logout
            </MenuItem>
        </Menu>
    )
}

export default LogoutMenu;