import React, {useState} from 'react'

const CHECKBOX_ELEMENT = "checkbox"

export function useForm(initialFValues, props) {

    const [values, setValues] = useState(initialFValues);
    const [errors, setErrors] = useState({});
    const {setOpen, setSelectedRow} = props;

    const handleInputChange = (e) => {
        let {name, value, type, checked} = e.target
        if (type === CHECKBOX_ELEMENT) {
            setValues({
                ...values,
                [name]: checked
            })
        } else {
            if (value === "None")
                value = null;
            setValues({
                ...values,
                [name]: value
            })
        }
    }

    const handleError = (fields) => {
        if (fields) {
            let tmp = {};
            fields.forEach(message => {
                const errorName = message.name;
                const errorMessage = message.messages.flatMap(it => it);
                tmp = {
                    ...tmp,
                    [errorName]: errorMessage
                };
            });
            setErrors(tmp);
        }
    }

    const resetForm = () => {
        setValues(initialFValues);
        setErrors({})
    }

    const handleClose = () => {
        setOpen(false);
        setSelectedRow("");
        resetForm();
    };

    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        handleClose,
        handleError
    }
}

export const Form = (props) => {
    return (
        <form>
            {props.children}
        </form>
    )
}