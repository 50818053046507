import React, { useEffect, useState } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ProjectDialog from "./ProjectForm";
import ToolBar from "../../components/Layout/ToolBar";
import useTable from '../../components/useTable';
import * as projectService from '../../services/projectService';
import ActionsTableCell from '../../components/MaterialTable/ActionsTableCell';
import useAlert from '../../components/useAlert';
import AlertDialog from '../../components/AlertDialog';


const projectColumns = [
    { id: 'id', label: 'ID' },
    { id: 'name', label: 'Name' },
];

const PROJECT_DIALOGS = {
    CREATE_EDIT_DIALOG: 'project',
    CONFIRMATION_DIALOG: 'alert'
}

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [open, setOpen] = useState(false);
    const [dialogType, setDialogType] = useState("");

    useEffect(() => {
        getProjects();
        //eslint-disable-next-line
    }, []);

    const {
        TblContainer,
        TblHead
    } = useTable(projects, projectColumns);

    const {
        Alert,
        status,
        showSuccess,
        showError
    } = useAlert();

    const getProjects = () => {
        projectService.getProjects()
            .then((response) => {
                const result = response.data.items;
                if (result.length > 0) {
                    setProjects(response.data.items);
                }
                return response.data.items;
            })
            .catch(error => {
                showError(error.response.data.message);
            });

    }

    const handleDeleteClicked = () => {
        projectService.removeProject(selectedRow.id)
            .then(() => {
                showSuccess();
                getProjects();
            }).catch(error => {
                showError(error.response.data.message);
            });
    }

    return (
        <React.Fragment>
            {status ? <Alert /> : null}
            <ToolBar
                title="Create new project"
                setOpen={() => {
                    setDialogType(PROJECT_DIALOGS.CREATE_EDIT_DIALOG);
                    setOpen(true);
                }}
            />
            <TblContainer>
                <TblHead />
                <TableBody>
                    {projects && projects.map(key => (
                        <TableRow
                            key={key.id}
                            onClick={() => setSelectedRow(key)}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell >{key.id}</TableCell>
                            <TableCell >{key.name}</TableCell>
                            <ActionsTableCell
                                onEdit={() => {
                                    setDialogType(PROJECT_DIALOGS.CREATE_EDIT_DIALOG);
                                    setOpen(true);
                                }}
                                onDelete={(e) => {
                                    setDialogType(PROJECT_DIALOGS.CONFIRMATION_DIALOG);
                                    setOpen(true);
                                }}
                            />
                        </TableRow>
                    ))}
                </TableBody>
            </TblContainer>
            {dialogType === PROJECT_DIALOGS.CREATE_EDIT_DIALOG ?
                <ProjectDialog
                    open={open}
                    setOpen={setOpen}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    getProjects={getProjects}
                />
                : dialogType === PROJECT_DIALOGS.CONFIRMATION_DIALOG ?
                    <AlertDialog
                        open={open}
                        setOpen={setOpen}
                        setSelectedRow={setSelectedRow}
                        confirmAction={handleDeleteClicked}
                    />
                    : null
            }
        </React.Fragment>
    );
}

export default Projects;
