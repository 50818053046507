import * as Api from '../api/Api';
import createAxios from '../api/WiseOwl';

export function getProjects() {
    return createAxios().get(Api.PATH_PROJECTS);
};

export const removeProject = (id) => {
    return createAxios().delete(Api.PATH_PROJECTS + "/" + id);
}


export const createProject = (values) => {
    return createAxios().post(Api.PATH_PROJECTS, values);

};

export const updateProject = (id, values) => {
    return createAxios().put(`${Api.PATH_PROJECTS}/${id}`, values);
};

export const getProjectClients = (projectId) => {
    return createAxios().get(`${Api.PATH_PROJECTS}/${projectId}${Api.SUB_PATH_PROJECT_CLIENTS}`);
}



