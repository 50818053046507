import React, { useState } from "react";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export default function useLinearProgress(loadingState) {

    const [isLoading, setIsLoading] = useState(loadingState);

    const LinearPorgress = () => (
            <Box sx={{ width: '100%', height: '100px', textAlign: 'center', justifyContent: 'center' }}>
                <Typography>Loading...</Typography>
                <LinearProgress />
            </Box>
        );

    return {
        LinearPorgress,
        isLoading,
        setIsLoading
    }
}