import React, {useEffect, useState} from 'react';
import Dialog from "../../components/controls/Dialog";
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FEConfigOptionalForm from './FEConfigOptionalForm';
import * as feconfigService from '../../services/feconfigService';
import {Form, useForm} from '../../components/useForm';
import useAlert from '../../components/useAlert';
import {FormHelperText, Typography} from '@mui/material';
import useLinearProgress from '../../components/useLinearProgress';
import locale from 'react-json-editor-ajrm/locale/en';
import {TextareaAutosize} from "@material-ui/core";

const feConfig = {}
const defaultConfigJsonName = "defaultConfigJson"

const FEConfigDialog = (props) => {
    const {open, selectedRow} = props;
    const [options, setOptions] = useState([]);
    const initialFValues = {
        defaultConfigJson: null,
    };

    useEffect(() => {
        if (open) {
            setIsLoading(true);
            handleAlertClose();
            resetDialog();
            feconfigService.getAll(selectedRow.id).then((response) => {
                const result = response.data;
                if (result) {
                    if (result.defaultConfigJson) {
                        handleJSONInputChange(result.defaultConfigJson)
                    }
                    if (result.items)
                        setOptions(result.items);
                }
            }).catch(error => {
                showError(error.response.data.message);
            }).finally(() => {
                setIsLoading(false);
            })
        }
        //eslint-disable-next-line
    }, [open])

    const {
        values,
        setValues,
        errors,
        handleClose,
        handleInputChange,
        handleError
    } = useForm(initialFValues, props);

    const {
        Alert,
        status,
        showSuccess,
        showError,
        handleAlertClose
    } = useAlert();

    const {
        LinearPorgress,
        isLoading,
        setIsLoading
    } = useLinearProgress(true);

    const submit = () => {
        const request = {
            defaultConfigJson: values.defaultConfigJson.length > 0? values.defaultConfigJson : null
        }
        if (options.length > 0)
            request.items = options
        else
            delete request.items

        feconfigService.update(selectedRow.id, request).then(() => {
            showSuccess();
            resetDialog();
            handleClose();
        })
            .catch(error => {
                handleError(error.response.data.fields);
                showError(error.response.data.message);
            }).finally(() => {
        })
    };

    const handleJSONInputChange = (input) => {
        setValues({
            ...values,
            [defaultConfigJsonName]: input
        })
    }

    const resetDialog = () => {
        setOptions([]);
    }

    return (
        <Form>
            {status ? <Alert/> : null}
            <Dialog
                title="Frontend JSON Configuration"
                open={open}
                fullWidth={true}
                handleClose={() => {
                    resetDialog();
                    handleClose();
                }
                }>
                {isLoading ? <LinearPorgress/> :
                    <React.Fragment>
                        <DialogContent>
                            <TextareaAutosize
                                defaultValue={values.defaultConfigJson ? values.defaultConfigJson : null}
                                placeholder={"Default JSON"}
                                style={{minHeight: 200, width: 500}}
                                locale={locale}
                                name = "defaultConfigJson"
                                onChange={handleInputChange}
                            />
                            {errors.defaultConfigJson
                                ? <FormHelperText error>{errors.defaultConfigJson}</FormHelperText> : null
                            }
                            {options.length > 0 &&
                            <Typography mt={2} variant="h6" gutterBottom component="div">Optionals</Typography>}
                            {options.map((it, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <FEConfigOptionalForm
                                            options={options}
                                            setOptions={setOptions}
                                            index={index}
                                            errors={errors}
                                        />
                                    </React.Fragment>
                                )
                            })}
                        </DialogContent>
                        <DialogActions>
                            {options.length > 0 ?
                                <Button onClick={() => setOptions(options.slice(0, -1))}>Delete</Button> : null}
                            <Button onClick={() => {
                                setOptions(it => [...it, feConfig]);
                            }
                            }>Add Optional</Button>
                        </DialogActions>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    resetDialog();
                                    handleClose();
                                }}>Cancel</Button>
                            <Button variant="contained" onClick={submit}>Confirm</Button>
                        </DialogActions>
                    </React.Fragment>
                }
            </Dialog>
        </Form>
    );
}

export default FEConfigDialog;
