import React, { useEffect } from 'react';
import Dialog from '../../../components/controls/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import * as watsonService from '../../../services/watsonService';
import Input from '../../../components/controls/Input';
import { useForm, Form } from '../../../components/useForm';
import useAlert from '../../../components/useAlert';

const initialFValues = {}

const WatsonConfigForm = (props) => {
    const { open, selectedRow, getWatsonConfigs } = props;

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleClose,
        handleError
    } = useForm(initialFValues, props);

    useEffect(() => {
        if (open)
            handleAlertClose();
        if (selectedRow)
            setValues({
                ...selectedRow
            });
        //eslint-disable-next-line
    }, [selectedRow, open])

    const {
        Alert,
        status,
        showSuccess,
        showError,
        handleAlertClose
    } = useAlert();

    const submit = () => {
        if (validate()) {
            if (selectedRow)
                watsonService.update(selectedRow.id, values)
                    .then(() => {
                        showSuccess();
                        handleClose();
                        getWatsonConfigs();
                    })
                    .catch(error => {
                        showError(error.response.data.message);
                        handleError(error.response.data.fields);
                    });
            else
                watsonService.createWatsonConfig(values)
                    .then((response) => {
                        showSuccess();
                        handleClose();
                        getWatsonConfigs();
                    })
                    .catch(error => {
                        showError(error.response.data.message);
                        handleError(error.response.data.fields);
                    });
        }
    };

    const validate = () => {
        let temp = {}
        // temp.name = values.name ? "" : "Name is required";
        // if (!temp.name)
        //     temp.name = values.name.length <= NAME_MAX_L ? "" :
        //         `Name should be at most ${NAME_MAX_L} characters long!`;

        // temp.apiKey = values.apiKey ? "" : "Api key is required";
        // if (!temp.apiKey)
        //     temp.apiKey = values.apiKey.length <= API_KEY_SIZE_MAX ? "" :
        //         `"Api key should be at most ${API_KEY_SIZE_MAX} characters long!`;

        // temp.assistantId = values.assistantId ? "" : "Assistant ID is required";
        // if (!temp.assistantId)
        //     temp.assistantId = values.assistantId.length <= ASSISTANT_ID_MAX ? "" :
        //         `Assistant ID should be at most ${ASSISTANT_ID_MAX} characters long!`;

        // temp.url = values.url ? "" : "URL is required";
        // if (!temp.url)
        //     temp.url = values.url.length <= URL_MAX ? "" :
        //         `URL should be at most ${URL_MAX} characters long!`;

        // temp.version = values.version ? "" : "Version is required";
        // if (!temp.version)
        //     temp.version = values.version.length <= VERSION_MAX ? "" :
        //         `Version should be at most ${VERSION_MAX} characters long!`;

        setErrors({
            ...temp
        });
        return Object.values(temp).every(x => x === '');
    }

    return (
        <Form>
            {status ? <Alert /> : null}
            <Dialog
                title={selectedRow ? "Edit Watson config" : "Create new Watson config"}
                open={open}
                handleClose={handleClose}>
                <DialogContent>
                    <Input
                        label="Api Key"
                        name="apiKey"
                        defValue={values.apiKey}
                        onChange={handleInputChange}
                        error={errors.apiKey}
                    />
                    <Input
                        label="Assistant ID"
                        name="assistantId"
                        defValue={values.assistantId}
                        onChange={handleInputChange}
                        error={errors.assistantId}
                    />
                    <Input
                        label="Name"
                        name="name"
                        defValue={values.name}
                        onChange={handleInputChange}
                        error={errors.name}
                    />
                    <Input
                        label="URL"
                        name="url"
                        defValue={values.url}
                        onChange={handleInputChange}
                        error={errors.url}
                    />
                    <Input
                        label="Version"
                        name="version"
                        defValue={values.version}
                        onChange={handleInputChange}
                        error={errors.version}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" onClick={submit}>Save</Button>
                </DialogActions>
            </Dialog>
        </Form>
    )
}

export default WatsonConfigForm;