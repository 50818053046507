export const API_V1_PREFIX = "/api/v1"

export const PATH_PROJECTS = `${API_V1_PREFIX}/projects`
export const SUB_PATH_PROJECT_CLIENTS = "/clients"

export const PATH_WATSONS = `${API_V1_PREFIX}/watsons`

export const PATH_RASAS = `${API_V1_PREFIX}/rasas`

export const PATH_CLIENTS = `${API_V1_PREFIX}/clients`
export const SUB_PATH_CLIENT_FEATURES = `/features`

export const SUB_PATH_CLIENT_WATSON = `/watson`
export const SUB_PATH_CLIENT_RASA = `/rasa`

export const SUB_PATH_CLIENT_FRONTEND_CONFIG = `/frontendConfig`
export const PATH_UNNECESSARY_PHRASES = `${API_V1_PREFIX}/unnecessaryPhrases`

export const PATH_TEXT_TO_SPEECH_TRANSFORM = `${API_V1_PREFIX}/textToSpeechTransform`
export const SUB_PATH_CLIENT_TEXT_TO_SPEECH_TRANSFORM = "/textToSpeechTransform"




