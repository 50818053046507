import { DialogTitle, Dialog as MUIDialoge } from '@mui/material';
import React from 'react';

const Dialog = (props) => {

    const { title, open, handleClose, fullWidth } = props;

    return (
        <MUIDialoge
            disableEnforceFocus
            open={open}
            {...(fullWidth && { fullWidth: true })}
            onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            {props.children}
        </MUIDialoge>
    )
}

export default Dialog;