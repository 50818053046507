import React, { useContext, useEffect, useState } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ToolBar from "../../components/Layout/ToolBar";
import IconButton from '@mui/material/IconButton';
import useTable from '../../components/useTable';
import * as clientService from '../../services/clientService';
import * as projectService from '../../services/projectService';
import ActionsTableCell from '../../components/MaterialTable/ActionsTableCell';
import ClientFeatureForm from '../Clients/ClienFeatureForm';
import ClientForm from './ClientForm';
import FEConfigDialog from './FEConfigForm';
import ClientAssistantDialog from './ClientAssistantForm';
import TTSSettingsForm from './TTSSettingsForm';
import { Paper, Typography } from '@mui/material';
import ProjectContext from '../../context/project-context';
import useAlert from '../../components/useAlert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AlertDialog from '../../components/AlertDialog';
import FeaturesSettingsIcon from '../../components/controls/FeaturesSettingsIcon';

const clientColumns = [
    { id: 'id', label: 'ID' },
    { id: 'name', label: 'Name' },
    { id: 'api-key', label: 'API Key' },
    { id: 'platform', label: 'Platform' },
    { id: 'language', label: 'Lan' },
    { id: 'type', label: 'Client Type' },
    { id: 'tts-provider', label: 'TTS Provider' },
    { id: 'ai', label: 'AI' }
];

const CLIENT_DIALOGS = {
    CREATE_EDIT: 'client',
    FECONFIG: 'feconfig',
    FEATURES: 'features',
    TTS: 'tts',
    AI: 'ai',
    CONFIRMATION: 'alert'
}

const Clients = () => {
    const [selectedRow, setSelectedRow] = useState("");
    const [dialogType, setDialogType] = useState("");
    const [clients, setClients] = useState([]);
    const [open, setOpen] = useState(false);
    const projectContext = useContext(ProjectContext);

    useEffect(() => {
        if (projectContext.selectedProject)
            getProjectClients();
        //eslint-disable-next-line
    }, [projectContext.selectedProject]);

    const {
        TblContainer,
        TblHead
    } = useTable(clients, clientColumns);

    const {
        showSuccess,
        showError,
    } = useAlert();

    const handleDeleteClicked = () => {
        clientService.removeClient(selectedRow.id)
            .then((response) => {
                showSuccess();
                getProjectClients();
            })
            .catch(error => {
                showError(error.response.data.message);
            });
    }

    const getProjectClients = () => {
        projectService.getProjectClients(projectContext.selectedProject.id)
            .then((response) => {
                const result = response.data;
                if (result.items) {
                    setClients(result.items);
                }
            })
            .catch(error => {
                showError(error.response.data.message);
            });
    }

    return (
        <Paper>
            <ToolBar
                title="Create new Api key"
                setOpen={() => setOpen(true)}
                setDialogType={() => {
                    setDialogType(CLIENT_DIALOGS.CREATE_EDIT);
                }}
            />
            <TblContainer>
                <TblHead />
                <TableBody>
                    {clients && clients.map(key => (
                        <TableRow
                            key={key.id}
                            onClick={() => setSelectedRow(key)}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell >{key.id}</TableCell>
                            <TableCell >{key.name}</TableCell>
                            <TableCell >{key.apiKey.substring(0, 12)}...{key.apiKey.substring(key.apiKey.length - 5, key.apiKey.length)}</TableCell>
                            <TableCell >{key.platform}</TableCell>
                            <TableCell >{key.language}</TableCell>
                            <TableCell >{key.type}</TableCell>
                            <TableCell  >
                                <IconButton
                                    sx={{
                                        alignItems: 'flex-start'
                                    }}
                                    onClick={() => {
                                        setDialogType(CLIENT_DIALOGS.TTS);
                                        setOpen(true);
                                    }}>
                                    <Typography sx={{ color: 'black' }}>{key.ttsProvider}</Typography>
                                    <EditOutlinedIcon
                                        fontSize="small"
                                    />
                                </IconButton>
                            </TableCell>
                            <TableCell >
                                <IconButton
                                    sx={{
                                        alignItems: 'flex-start'
                                    }}
                                    onClick={() => {
                                        setDialogType(CLIENT_DIALOGS.AI);
                                        setOpen(true);
                                    }}>
                                    <Typography sx={{ color: 'black' }}>{key.engineType}</Typography>
                                    <EditOutlinedIcon
                                        fontSize="small"
                                    />
                                </IconButton>
                            </TableCell>
                            <ActionsTableCell
                                onEdit={() => {
                                    setDialogType(CLIENT_DIALOGS.CREATE_EDIT);
                                    setOpen(true);
                                }}
                                onDelete={() => {
                                    setDialogType(CLIENT_DIALOGS.CONFIRMATION);
                                    setOpen(true);
                                }
                                }
                            >
                                <IconButton
                                    onClick={() => {
                                        setDialogType(CLIENT_DIALOGS.FECONFIG);
                                        setOpen(true);
                                    }}>
                                    <Typography color="primary">JSON</Typography>
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        setDialogType(CLIENT_DIALOGS.FEATURES);
                                        setOpen(true);
                                    }}>
                                    <FeaturesSettingsIcon />
                                </IconButton>
                            </ActionsTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </TblContainer>
            {dialogType === CLIENT_DIALOGS.CREATE_EDIT ?
                <ClientForm
                    open={open}
                    setOpen={setOpen}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    getProjectClients={getProjectClients}
                />
                : (dialogType === CLIENT_DIALOGS.FECONFIG) ?
                    <FEConfigDialog
                        open={open}
                        setOpen={setOpen}
                        selectedRow={selectedRow}
                        setSelectedRow={setSelectedRow}
                    /> : (dialogType === CLIENT_DIALOGS.FEATURES) ?
                        <ClientFeatureForm
                            open={open}
                            setOpen={setOpen}
                            selectedRow={selectedRow}
                            setSelectedRow={setSelectedRow}
                        /> : (dialogType === CLIENT_DIALOGS.AI) ?
                            <ClientAssistantDialog
                                open={open}
                                setOpen={setOpen}
                                selectedRow={selectedRow}
                                setSelectedRow={setSelectedRow}
                            /> : (dialogType === CLIENT_DIALOGS.TTS) ?
                                <TTSSettingsForm
                                    open={open}
                                    setOpen={setOpen}
                                    selectedRow={selectedRow}
                                    setSelectedRow={setSelectedRow}
                                /> : dialogType === CLIENT_DIALOGS.CONFIRMATION ?
                                    <AlertDialog
                                        open={open}
                                        setOpen={setOpen}
                                        setSelectedRow={setSelectedRow}
                                        confirmAction={handleDeleteClicked}
                                    />
                                    : null
            }
        </Paper>
    );
}

export default Clients;
