import React, { useEffect } from 'react';
import Dialog from "../../components/controls/Dialog";
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import * as projectService from '../../services/projectService';
import Input from '../../components/controls/Input';
import { useForm } from '../../components/useForm';
import useAlert from '../../components/useAlert';

const initialFValues = {}

const ProjectDialog = (props) => {
    const { open, selectedRow, getProjects } = props;

    const {
        values,
        setValues,
        errors,
        handleInputChange,
        handleClose,
        handleError
    } = useForm(initialFValues, props);

    useEffect(() => {
        if (open)
            handleAlertClose();
        if (selectedRow)
            setValues({
                ...selectedRow
            });
        //eslint-disable-next-line
    }, [selectedRow, open])

    const {
        Alert,
        status,
        showSuccess,
        showError,
        handleAlertClose
    } = useAlert();

    const submit = () => {
        if (validate()) {
            if (selectedRow)
                projectService.updateProject(selectedRow.id, values)
                    .then(() => {
                        showSuccess();
                        handleClose();
                        getProjects();
                    })
                    .catch(error => {
                        showError(error.response.data.message);
                        handleError(error.response.data.fields);
                    });
            else
                projectService.createProject(values)
                    .then((response) => {
                        showSuccess();
                        handleClose();
                        getProjects();
                    })
                    .catch(error => {
                        showError(error.response.data.message);
                        handleError(error.response.data.fields);
                    });;
        }
        console.log(errors);
    };

    const validate = () => {
        let temp = {}
        // temp.id = values.id ? "" : "ID  is required";
        // if (!temp.id)
        //     temp.id = values.id.length <= ID_MAX_L && values.id.length >= ID_MIN_L ? "" :
        //         `Project ID should be between ${ID_MIN_L} to ${ID_MAX_L} characters!`;
        // temp.name = values.name ? "" : "Name is required";
        // if (!temp.name)
        //     temp.name = values.name.length <= NAME_MAX_L ? "" :
        //         `Name should be at most ${NAME_MAX_L} characters long!`;
        // setErrors({
        //     ...temp
        // });
        return Object.values(temp).every(x => x === '');
    }

    return (
        <React.Fragment>
            {status ? <Alert /> : null}
            <Dialog
                title={selectedRow ? "Edit project" : "Create new project"}
                open={open}
                handleClose={handleClose}>
                <DialogContent>
                    <Input
                        {...(selectedRow && { disabled: true })}
                        label="Project ID"
                        name="id"
                        defValue={values.id}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        onChange={handleInputChange}
                        error={errors.id}
                    />
                    <Input
                        label="Project name"
                        name="name"
                        defValue={values.name}
                        onChange={handleInputChange}
                        error={errors.name}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" onClick={submit}>Save</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default ProjectDialog;