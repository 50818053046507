import * as Api from '../api/Api';
import createAxios from '../api/WiseOwl';

export const getAll = (clientId) => {
    return createAxios().get(`${Api.PATH_CLIENTS}/${clientId}${Api.SUB_PATH_CLIENT_FRONTEND_CONFIG}`);
}


export const update = (clientId, values) => {
    return createAxios().put(`${Api.PATH_CLIENTS}/${clientId}${Api.SUB_PATH_CLIENT_FRONTEND_CONFIG}`, values);

}
