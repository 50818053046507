import React, { useEffect } from 'react';
import Dialog from '../../../components/controls/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import * as rasaService from '../../../services/rasaService';
import Input from '../../../components/controls/Input';
import { useForm, Form } from '../../../components/useForm';
import useAlert from '../../../components/useAlert';

const initialFValues = {}

const RasaConfigForm = (props) => {
    const { open, selectedRow, getRasaConfigs } = props;

    const {
        values,
        setValues,
        errors,
        handleInputChange,
        handleClose,
        handleError
    } = useForm(initialFValues, props);

    useEffect(() => {
        if (open)
            handleAlertClose();
        if (selectedRow)
            setValues({
                ...selectedRow
            });
        //eslint-disable-next-line
    }, [selectedRow, open])

    const {
        Alert,
        status,
        showSuccess,
        showError,
        handleAlertClose
    } = useAlert();

    const submit = () => {
        if (validate()) {
            if (selectedRow)
                rasaService.update(selectedRow.id, values)
                    .then(() => {
                        showSuccess();
                        handleClose();
                        getRasaConfigs();
                    })
                    .catch(error => {
                        showError(error.response.data.message);
                        handleError(error.response.data.fields);
                    });
            else
                rasaService.createRasaConfig(values)
                    .then(() => {
                        showSuccess();
                        handleClose();
                        getRasaConfigs();
                    })
                    .catch(error => {
                        showError(error.response.data.message);
                        handleError(error.response.data.fields);
                    });
        }
    };

    const validate = () => {
        let temp = {}
        return Object.values(temp).every(x => x === '');
    }

    return (
        <Form>
            {status ? <Alert /> : null}
            <Dialog
                title={selectedRow ? "Edit Rasa config" : "Create new Rasa config"}
                open={open}
                handleClose={handleClose}>
                <DialogContent>
                    <Input
                        label="Name"
                        name="name"
                        defValue={values.name}
                        onChange={handleInputChange}
                        error={errors.name}
                    />
                    <Input
                        label="URL"
                        name="url"
                        defValue={values.url}
                        onChange={handleInputChange}
                        error={errors.url}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" onClick={submit}>Save</Button>
                </DialogActions>
            </Dialog>
        </Form>
    )
}

export default RasaConfigForm;