import * as Api from '../api/Api';
import createAxios from '../api/WiseOwl';

export const getAll = () => {
    return createAxios().get(Api.PATH_WATSONS);
};

export const removeWatsonConfig = (id) => {
    return createAxios().delete(Api.PATH_WATSONS + "/" + id);
}

export const createWatsonConfig = (values) => {
    return createAxios().post(Api.PATH_WATSONS, values)
};

export const update = (id, values) => {
    return createAxios().put(`${Api.PATH_WATSONS}/${id}`,values);
};