import React, {useContext, useEffect} from 'react';
import Dialog from '../../components/controls/Dialog';
import Button from '@mui/material/Button';
import Input from '../../components/controls/Input';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import * as clientService from '../../services/clientService';
import {useForm} from '../../components/useForm';
import ProjectContext from '../../context/project-context';
import {v4 as uuidv4} from 'uuid';
import Select from '../../components/controls/Select';
import useAlert from '../../components/useAlert';
import {Stack} from '@mui/material';
import * as PREDEFINED_VALUES from '../../const/consts'
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const initialFValues = {
    engineType: PREDEFINED_VALUES.engineTypes.WATSON,
    ttsProvider: PREDEFINED_VALUES.ttsTypes[0],
    language: PREDEFINED_VALUES.languages[0],
    platform: PREDEFINED_VALUES.platforms[0],
    isActive: true,
    baseUrl: PREDEFINED_VALUES.DEFAULT_BASE_URL
}
const apiKeyName = 'apiKey';

const ClientForm = (props) => {
    const {open, selectedRow, getProjectClients} = props;
    const projectContext = useContext(ProjectContext);
    initialFValues.projectId = projectContext.selectedProject.id;

    const {
        values,
        setValues,
        errors,
        handleInputChange,
        handleClose,
        handleError,
    } = useForm(initialFValues, props);

    useEffect(() => {
        if (open)
            handleAlertClose();

        if (selectedRow)
            setValues({
                ...selectedRow
            });
        //eslint-disable-next-line
    }, [selectedRow, open])

    const {
        Alert,
        status,
        showSuccess,
        showError,
        handleAlertClose
    } = useAlert();

    const submit = () => {
        if (validate()) {
            if (selectedRow)
                clientService.updateClient(selectedRow.id, values)
                    .then((response) => {
                        showSuccess();
                        handleClose();
                        getProjectClients();
                    })
                    .catch(error => {
                        handleError(error.response.data.fields);
                        showError(error.response.data.message);
                    });
            else
                clientService.createClient(values)
                    .then((response) => {
                        showSuccess();
                        handleClose();
                        getProjectClients();
                    })
                    .catch(error => {
                        handleError(error.response.data.fields);
                        showError(error.response.data.message);
                    });
        }
    };


    const createApiKey = (e) => {
        let apiKey = uuidv4();
        setValues({
            ...values,
            [apiKeyName]: apiKey
        });
    }

    const validate = () => {
        //let temp = {}
        // temp.id = values.id ? "" : "ID  is required";
        // if (!temp.id)
        //     temp.id = values.id.length <= ID_SIZE_MAX && values.id.length >= ID_SIZE_MIN ? "" :
        //         `Client ID should be between ${ID_SIZE_MIN} to ${ID_SIZE_MAX} characters`;
        // temp.name = values.name ? "" : "Name is required";
        // if (!temp.name)
        //     temp.name = values.name.length <= NAME_SIZE_MAX ? "" :
        //         `Name should be at most ${NAME_SIZE_MAX} characters long!`;
        // temp.apiKey = values.apiKey ? "" : "Api key is required";
        // if (!temp.apiKey)
        //     temp.apiKey = values.apiKey.length <= API_KEY_SIZE_MAX ? "" :
        //         `"Api key should be at most ${API_KEY_SIZE_MAX} characters long!`;
        // setErrors({
        //     ...temp
        // });
        // return Object.values(temp).every(x => x == '');
        return true;
    }

    return (
        <React.Fragment>
            {status ? <Alert/> : null}
            <Dialog
                title={selectedRow ? "Edit API Key" : "Create new API Key"}
                open={open}
                handleClose={handleClose}>
                <DialogContent>
                    <Input
                        label="Client ID"
                        name="id"
                        {...(selectedRow && {disabled: true})}
                        defValue={values.id}
                        inputProps={{style: {textTransform: "uppercase"}}}
                        onChange={handleInputChange}
                        error={errors.id}
                    />
                    <Stack
                        direction="row"
                        spacing={1}
                    >
                        <Input
                            label="Api Key"
                            name="apiKey"
                            InputLabelProps={{shrink: true}}
                            value={values.apiKey ?? ''}
                            onChange={handleInputChange}
                            error={errors.apiKey}
                        />
                        {selectedRow ? null : <Button component="span" onClick={createApiKey}>Generate</Button>}
                    </Stack>
                    <Input
                        label="Name"
                        name="name"
                        defValue={values.name}
                        onChange={handleInputChange}
                        error={errors.name}
                    />
                    <Select
                        label="Language"
                        name="language"
                        defValue={selectedRow ? selectedRow.language : PREDEFINED_VALUES.languages[0]}
                        options={PREDEFINED_VALUES.languages}
                        onChange={handleInputChange}
                        error={errors.language}
                    />
                    <Select
                        label="Platform"
                        name="platform"
                        defValue={selectedRow ? selectedRow.platform : PREDEFINED_VALUES.platforms[0]}
                        options={PREDEFINED_VALUES.platforms}
                        onChange={handleInputChange}
                    />
                    <Select
                        label="Type"
                        name="type"
                        options={PREDEFINED_VALUES.clientTypes}
                        {...(selectedRow && {defValue: selectedRow.type})}
                        onChange={handleInputChange}
                    />
                    <Select
                        label="TTS Provider"
                        name="ttsProvider"
                        defValue={selectedRow ? selectedRow.ttsProvider : PREDEFINED_VALUES.ttsTypes[0]}
                        options={PREDEFINED_VALUES.ttsTypes}
                        onChange={handleInputChange}
                    />
                    <Select
                        label="Engine type"
                        name="engineType"
                        defValue={selectedRow ? selectedRow.engineType : PREDEFINED_VALUES.engineTypes.WATSON}
                        options={Object.values(PREDEFINED_VALUES.engineTypes)}
                        onChange={handleInputChange}
                    />
                    <Input
                        label="Client service base URL"
                        name="baseUrl"
                        defValue={selectedRow ? selectedRow.baseUrl : PREDEFINED_VALUES.DEFAULT_BASE_URL}
                        onChange={handleInputChange}
                        error={errors.baseUrl}
                    />
                    {selectedRow ?
                        <FormControlLabel control={<Checkbox
                            name="isActive"
                            defaultChecked={selectedRow ? selectedRow.isActive : true}
                            onChange={handleInputChange}/>} label="Is active"/>
                        :null}
                    <FormControlLabel control={<Checkbox
                        name="isTestingTool"
                        defaultChecked={selectedRow ? selectedRow.isTestingTool : false}
                        onChange={handleInputChange}/>} label="Testing tool"/>
                    <FormControlLabel control={<Checkbox
                        name="isWatsonUIClientActive"
                        defaultChecked={selectedRow ? selectedRow.isWatsonUIClientActive : false}
                        onChange={handleInputChange}/>} label="Watson UI client active"/>
                    <FormControlLabel control={<Checkbox
                        name="genericHandling"
                        defaultChecked={selectedRow ? selectedRow.genericHandling : false}
                        onChange={handleInputChange}/>} label="Generic handling"/>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" onClick={submit}>Save</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default ClientForm;
